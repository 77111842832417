<template>
    <MainLayout>
        <div v-if="data" class="container">
            <div class="go-back-container">
                <button class="blue-link" @click="goBack">BACK</button>
            </div>
            <div class="page-title-container">
                <div class="page-title">
                    Amend Invoice #{{ data.invoice_no }}
                </div>
            </div>
            <span class="divider"></span>
            <div v-if="loading" class="loading-animation">
                <font-awesome-icon icon="spinner"></font-awesome-icon>
            </div>
            <template v-else-if="data">
                <InvoiceForm @onSubmit="handleGenerateInvoice" :data="data" />
            </template>
            <span class="divider"></span>
            <div class="form-action-container">
                <button
                    class="button yellow lg"
                    :disabled="isSubmitting"
                    @click="submit"
                >
                    {{ isSubmitting ? "Saving..." : "Save" }}
                </button>
            </div>
        </div>

        <ModalLayout
            :isOpen="isFeedbackOpen"
            :isSkipEscapeKey="true"
            @onClose="closeFeedbackModal"
            @onRoot="setModalRef"
        >
            <InvoiceFeedbackModal
                @onClose="closeFeedbackModal"
                :print="print"
                :feedback="feedbackMessage"
                :invoiceId="feedbackTarget?.invoice_id || null"
            ></InvoiceFeedbackModal>
        </ModalLayout>
    </MainLayout>
</template>

<script setup>
import { onMounted, ref } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import InvoiceFeedbackModal from "../components/modal/InvoiceFeedbackModal.vue"
import InvoiceForm from "../components/form/InvoiceForm.vue"
import { getApi, postApi, printInvoiceByID } from "../util/dodoo"
import { useRouter, useRoute } from "vue-router"
import { toast, setLoader } from "../util/dodoo"

// const citySuggestions = ref(new Map());

const defaultBilling = {
    company_name: "",
    name: "",
    contact: "",
    unit_no: "",
    address: "",
    postcode: "",
    state: "",
    city: "",
}

const defaultParcelDetail = {
    description: "",
    value: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    tracking_no: "",
}

const defaultRecipient = {
    is_using_invoice_address: true,
    ...defaultBilling,
    ...defaultParcelDetail,
}

const route = useRoute()
const router = useRouter()

const isFeedbackOpen = ref(false)
const feedbackTarget = ref()
const feedbackMessage = ref()

// const primaryFormRef = ref();
// const isUsePrimary = ref(false);

const isSubmitting = ref(false)

const data = ref()

const modalRef = ref()

const loading = ref(true)

const setModalRef = (r) => {
    modalRef.value = r
}

const closeFeedbackModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isFeedbackOpen.value = false
            },
            { once: true }
        )
    } else {
        isFeedbackOpen.value = false
    }
}

const submit = () => {
    document.querySelector("#invoiceForm").requestSubmit()
}

const handleEditInvoice = async (fd) => {
    isSubmitting.value = true
    fd.append("id", route.params.invoice_id)

    const response = await postApi("/invoices/edit", fd)
    isSubmitting.value = false
    // isUsePrimary.value = false;
    if (response.error) {
        toast(response.error, "error")
    }
    if (response.id) {
        toast("Successfully Edited")
        data.value = response
        feedbackTarget.value = response
        feedbackMessage.value = {
            title: "Invoice Created",
            desc: "Transaction has been recorded.",
        }
        isFeedbackOpen.value = true
    }
}

const goBack = () => {
    router.push("/")
}

const sliceRecipientByName = (name) => {
    const regExp = /\[.*?\]/g
    const matches = name.match(regExp)
    if (matches) {
        return matches.map((m) => m.replace("[", "").replace("]", ""))
    }
    return null
}

const serializeNestedbject = (obj, name) => {
    let out = {}
    for (let k in obj) {
        const v = obj[k]
        if (Array.isArray(v)) {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}` : k}`),
            }
        } else if (typeof v === "object") {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}[${k}]` : k}`),
            }
        } else {
            out[`${name ? `${name}[${k}]` : k}`] = v
        }
    }
    return out
}

const handleGenerateInvoice = (e) => {
    setLoader(true)
    delete e.version_history
    const payload = serializeNestedbject(e)
    const fd = new FormData()
    for (let k in payload) {
        if (k.includes("recipients")) {
            const [index, val] = sliceRecipientByName(k)
            if (payload[`recipients[${index}][is_using_invoice_address]`]) {
                fd.set(k, payload[val] || payload[k])
            } else {
                fd.append(k, payload[k])
            }
        } else {
            fd.append(k, payload[k])
        }
    }
    handleEditInvoice(fd)
}

const print = (invoice_id) => {
    printInvoiceByID(invoice_id)
}

const generateApiUrl = () => {
    const base = `/invoices/detail/${route.params.invoice_id}`
    return base
}

const fetchData = async () => {
    loading.value = true
    const url = generateApiUrl()
    const result = await getApi(url)
    if (result.unauth) {
        console.log("GO LOGIN")
        router.push({ path: "/login", query: { status: "unauth" } })
        return
    }
    if (!result || result.error) {
        toast(result.error || "Error fetching data ", "error")
        return
    }
    data.value = result
    loading.value = false
}

onMounted(() => {
    // Dev_only
    window.add = () => {
        data.value.recipients.push({ ...defaultRecipient })
    }

    setLoader(true)
    fetchData()
})
</script>

<style scoped></style>
