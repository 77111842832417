<template>
    <div class="modal-container">
        <div>
            <template v-if="props.targetInvoice && props.targetInvoice.length">
                <div class="table-scollable">
                    <table class="table sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Invoice No.</th>
                                <th>Invoice Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(d, i) in props.targetInvoice"
                                :key="d.id"
                            >
                                <td>{{ i + 1 }}</td>
                                <td>{{ d.invoice_no }}</td>
                                <td>{{ d.created_at.slice(0, 10) }}</td>
                                <td
                                    :class="
                                        d.status === 'Paid'
                                            ? 'status-green'
                                            : 'status-red'
                                    "
                                >
                                    {{ d.status }}
                                </td>
                                <td>
                                    <div class="table-action-container">
                                        <button
                                            class="button blue"
                                            @click="props.print(d.id)"
                                        >
                                            <font-awesome-icon
                                                icon="print"
                                            ></font-awesome-icon>
                                            <span>Print</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <div v-else class="table-no-data">
                <font-awesome-icon icon="circle-question"></font-awesome-icon>
                NO DATA
            </div>
        </div>
        <span class="divider"></span>
        <div class="form-action-container">
            <button
                @click="handleClose"
                class="button yellow-outline"
                ref="button"
            >
                Close
            </button>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from "vue"
const props = defineProps({
    targetInvoice: Array,
    print: Function,
})

const button = ref()

const emit = defineEmits(["onClose"])

const handleClose = () => {
    emit("onClose")
}

onMounted(() => {
    if (button.value) {
        button.value.focus()
    }
})
</script>

<style scoped>
.modal-container {
    padding: 30px;
}
</style>
