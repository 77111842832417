<template>
    <LoginLayout>
        <div class="login-container">
            <span></span>
            <form @submit.prevent="handleLogin">
                <div class="logo-div">
                    <img class="main-logo" :src="companyLogoWithNumber" />
                </div>
                <label>Email</label>
                <input type="email" name="email" required />
                <label>Password</label>
                <input type="password" name="password" required />
                <button class="button yellow" :disabled="isLogin">
                    {{ isLogin ? "Logging in..." : "Login" }}
                </button>
            </form>
            <div class="footer">
                Copyrights © {{ year }} All Rights Reserved by KT Express
                Logistics Sdn Bhd (1146177-A)
            </div>
        </div>
    </LoginLayout>
</template>

<script setup>
// import { inject } from "@vue/runtime-core";
import { onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import { useRouter } from "vue-router"
import { postApi, toast } from "../util/dodoo"
import LoginLayout from "../components/layout/LoginLayout.vue"
const companyLogoWithNumber = require("../../../public/assets/img/dark-com-number.png")

const route = useRoute()
const router = useRouter()
const isLogin = ref(false)
const year = ref(new Date().getFullYear())

const handleLogin = async (e) => {
    isLogin.value = true
    const fd = new FormData(e.target)
    // const response = await dodoo.post("/api/auth/login", fd);
    const loginResponse = await postApi("/api/auth/login", fd)

    if (loginResponse.access_token) {
        localStorage.setItem("access_token", loginResponse.access_token)
        router.push("/")
    } else {
        toast("Invalid credentials", "error")
    }
    // console.log({response})
    isLogin.value = false
}

onMounted(() => {
    const status = route.query.status
    if (status === "unauth") {
        toast("You are not authorized", "error")
    }
    if (status === "logout") {
        toast("Successfully logout")
    }
    router.replace({ query: {} })
})
</script>

<style scoped>
.login-container {
    min-height: 100vh;
    background-color: var(--color-kt-blue);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.logo-div {
    margin-bottom: 40px;
}
.logo-div img {
    width: 100%;
}
form {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: left;
}
label {
    color: var(--color-kt-yellow);
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: bold;
    /* font-size: 17px; */
}
input {
    width: 100%;
    margin-bottom: 16px;
}
button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    margin: 16px auto 0;
}
.footer {
    font-size: 17px;
    color: #979797;
    text-align: center;
    padding-bottom: 16px;
}
</style>
