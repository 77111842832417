<template>
    <router-view />
    <ToastLayout :toastList="toastList"></ToastLayout>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from "vue"
import ToastLayout from "./components/layout/ToastLayout.vue"
import { xhrLogs, toastList } from "./util/dodoo"

const keys = new Set()

const handleKeydown = (e) => {
    keys.add(e.key)
    if (keys.has("Control") && keys.has("k") && keys.has("t")) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(JSON.stringify(xhrLogs))
            console.log("Exported")
        } else {
            console.log("Clipboard not supported")
        }
    }
}
const handleKeyup = (e) => {
    keys.delete(e.key)
}

onMounted(() => {
    if (location.protocol === "http:") {
        window.xhrLogger = () => {
            return xhrLogs
        }
    }

    document.addEventListener("keydown", handleKeydown)
    document.addEventListener("keyup", handleKeyup)
})

onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown)
    document.removeEventListener("keyup", handleKeyup)
})
</script>

<style>
:root {
    --color-kt-blue: #0b3459;
    --color-kt-yellow: #ffb400;
    --color-kt-red: red;
    --color-kt-green: #28a745;

    --color-toast-success: var(--color-kt-blue);
    --color-toast-error: var(--color-kt-red);

    --top-nav-height: 60px;
    --container-size: 80%;
}
#app,
#modal {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /* color: #2c3e50; */
}
::placeholder {
    color: #ccc !important;
}
.wrapper {
    position: relative;
}
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

* {
    margin: 0;
    box-sizing: border-box;
}
.container {
    width: var(--container-size);
    margin: 0 auto;
    padding: 24px 0;
}
.container > div + div {
    margin-top: 24px;
}
.go-back-container {
    text-align: left;
}
.page-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    text-align: left;
}
.page-title {
    font-size: 30px;
    color: var(--color-kt-blue);
}
.page-title-action {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.page-title-action {
    row-gap: 16px;
    column-gap: 16px;
}
.divider {
    display: block;
    height: 1px;
    background-color: #d8d8d8;
    width: 100%;
    margin: 24px 0;
}
.divider.h {
    widows: 1px;
    height: 100%;
    margin: 0 24px;
}
button {
    cursor: pointer;
    border: none;
    transition: 80ms;
    user-select: none;
}
button:disabled {
    cursor: not-allowed;
}
.button {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 40px;
    min-width: 120px;
    background-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    white-space: nowrap;
}
.button.lg {
    height: 60px;
    padding: 0 28px;
    font-size: 20px;
}
.button.blue-outline {
    font-size: 17;
    gap: 8px;
    padding: 0 12px;
    color: var(--color-kt-blue);
    background-color: white;
    border-color: var(--color-kt-blue);
}
.button.red-outline {
    font-size: 17;
    gap: 8px;
    padding: 0 12px;
    color: var(--color-kt-red);
    background-color: white;
    border-color: var(--color-kt-red);
}
.button.blue {
    font-size: 17;
    gap: 8px;
    padding: 0 12px;
    background-color: var(--color-kt-blue);
    color: white;
    border-color: var(--color-kt-blue);
}
.button.yellow {
    color: white;
    background-color: var(--color-kt-yellow);
    border-color: var(--color-kt-yellow);
}
.button.yellow:disabled {
    color: white;
    background-color: #ddd;
    border-color: #ddd;
}
.button.yellow-outline {
    color: var(--color-kt-yellow);
    background-color: transparent;
    border-color: var(--color-kt-yellow);
}
.button.yellow-outline:disabled {
    color: #ddd;
    border-color: #ddd;
}
.button.red {
    color: white;
    background-color: var(--color-kt-red);
    border-color: var(--color-kt-red);
}
.button.red:disabled {
    color: white;
    background-color: #ddd;
    border-color: #ddd;
}
.button.red-outline {
    color: var(--color-kt-red);
    background-color: white;
    border-color: var(--color-kt-red);
}
.button.red-outline:disabled {
    color: #ddd;
    border-color: #ddd;
}
.button.with-icon {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
}
.btn-dropdown-container {
    position: relative;
}
.btn-dropdown-container button {
    padding: 0;
}
.btn-dropdown-container button > :first-child {
    padding-left: 16px;
}
.btn-dropdown-container button > :last-child {
    padding-right: 16px;
}
.btn-dropdown-container button {
    justify-content: space-between;
    width: 160px;
}
.btn-dropdown-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 4px 0;
}
ul.btn-dropdown-menu li {
    padding: 8px 16px;
    list-style: none;
    cursor: pointer;
}
ul.btn-dropdown-menu li[disabled] {
    cursor: not-allowed;
    color: #ccc;
}
ul.btn-dropdown-menu li:not([disabled]):hover {
    cursor: pointer;
    background-color: #eee;
}
.btn-dropdown {
    position: relative;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 120px;
    background-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    white-space: nowrap;
}
.btn-dropdown.blue-outline {
    gap: 8px;
    color: var(--color-kt-blue) !important;
    background-color: white;
    border-color: var(--color-kt-blue) !important;
}
.btn-dropdown .dropdown-button {
    position: relative;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 0;
    height: 40px;
    min-width: 120px;
    background-color: transparent;
    white-space: nowrap;
}

.blue-outline .dropdown-button {
    gap: 8px;
    color: var(--color-kt-blue) !important;
    border-color: var(--color-kt-blue) !important;
    border: none;
}
/* .btn-dropdown > .button:first-child {
    padding-right: unset;
} */
.dropdown-chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    height: 100%;
    width: 40px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.btn-dropdown .dropdown-chevron:hover {
    background-color: #ddd;
}
.btn-dropdown-selection {
    display: none;
    top: 100%;
    position: absolute;
    /* background-color: white; */
    width: calc(100% + 4px);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}
.btn-dropdown.show .btn-dropdown-selection {
    display: block;
}
.btn-dropdown.show .btn-dropdown-selection:hover {
    background-color: #ddd;
}
.blue-link {
    height: fit-content;
    color: var(--color-kt-blue);
    background-color: transparent;
    border-bottom: 1px solid var(--color-kt-blue);
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
}
.red-link {
    height: fit-content;
    color: var(--color-kt-red);
    background-color: transparent;
    border-bottom: 1px solid var(--color-kt-red);
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
}
.square-button {
    flex-shrink: 0;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
}
.square-button.red {
    color: white;
    background-color: var(--color-kt-red);
    border-color: var(--color-kt-red);
}
.square-button.blue {
    color: white;
    background-color: var(--color-kt-blue);
    border-color: var(--color-kt-blue);
}
.square-button:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #979797;
}
.action-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
.search-description {
    text-align: left;
    font-size: 14px;
    color: #666;
    margin-top: 10px !important;
}
.sub-nav {
    display: flex;
    border-bottom: 1px solid #eee;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}
.sub-nav::-webkit-scrollbar {
    display: none;
}
.exp-sub-nav {
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.exp-sub-nav::before,
.exp-sub-nav::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100px;
    top: 0;
    z-index: 1;
}
.exp-sub-nav::before {
    background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        white 80%,
        white 50%
    );
    left: -1px;
}
.exp-sub-nav::after {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        white 80%,
        white 50%
    );
    right: -1px;
}
.exp-sub-nav-button-container {
    display: flex;
    overflow-x: scroll;
}
.sub-nav-button {
    font-size: 17px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
    height: 40px;
    border-radius: 0;
    border-width: 1px;
    border-width: 0;
    border-color: transparent;
    color: #979797;
    background-color: transparent;
    white-space: nowrap;
}
.sub-nav-button:first-child {
    border-top-left-radius: 4px;
}
.sub-nav-button:last-child {
    border-top-right-radius: 4px;
}
.sub-nav-button.active {
    font-weight: bold;
    color: var(--color-kt-yellow);
}
.sub-nav-button.active::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--color-kt-yellow);
    position: absolute;
    left: 0;
    bottom: -1px;
}
.sub-nav-bubble {
    border-radius: 4px;
    background-color: var(--color-kt-yellow);
    color: white;
    padding: 4px;
    font-size: 12px;
    min-width: 20px;
}
.table-container {
    /* overflow-x: auto; */
    padding-bottom: 24px;
}
.table-scollable {
    overflow-x: auto;
    padding-bottom: 24px;
}
.table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
    font-size: 15px;
}
.table th {
    height: 50px;
    padding: 0 12px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid var(--color-kt-blue);
    white-space: nowrap;
}
.table td {
    height: 100px;
    padding: 0 12px;
    white-space: nowrap;
}
.table.sm td {
    height: 60px;
}
.table tbody tr:nth-child(odd) {
    background-color: #eee;
}
.table tbody {
    border-bottom: 2px solid #eee;
}

.table-action-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
}
.table-action-container > * {
    /* min-width: 150px;
    justify-content: space-around; */
}
.table-action-container > * + * {
    margin-left: 16px;
}
select.table-select-input {
    height: 40px;
    padding: 4px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #979797;
    font-size: 17px;
    cursor: pointer;
}
.pagination-container {
    margin-top: 24px;
    text-align: center;
}
.pagination-container > * + * {
    margin-top: 8px;
}
.pagination-detail {
    font-size: 15px;
}
.pagination-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 25px;
    color: white;
    border-radius: 0;
    background-color: #aaa;
}
.pagination-button:hover {
    background-color: var(--color-kt-yellow);
}

.pagination-button:disabled {
    background-color: #ccc;
}
.pagination-button-container > button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.pagination-button-container > button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

input[type="text"],
input[type="date"],
input[type="tel"],
input[type="search"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="number"],
select,
textarea {
    height: 40px;
    padding: 4px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #979797;
    font-size: 17px;
}
[list]::-webkit-calendar-picker-indicator {
    display: none !important;
}

textarea {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 500;
    height: unset;
    resize: none;
}

input[type="search"],
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="text"]:invalid,
input[type="tel"]:invalid,
input[type="search"]:invalid,
input[type="url"]:invalid,
input[type="email"]:invalid,
input[type="password"]:invalid,
input[type="number"]:invalid,
select:invalid,
textarea:invalid {
    outline-color: var(--color-kt-red);
}

input:disabled,
select:disabled,
textarea:disabled {
    cursor: not-allowed;
    color: #ddd;
    border-color: #ddd;
}

label[required]::after {
    content: " *";
    color: red;
}

.loading-animation {
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: 80px auto;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #979797;
}
.simple-spin {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.table-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 80px 0;
    font-weight: bold;
    color: #979797;
    font-size: 28px;
}
.table-no-data svg {
    font-size: 32px;
}

.loading-placeholder {
    cursor: none;
    background: linear-gradient(0.25turn, transparent, #fff, transparent),
        linear-gradient(#ddd, #ddd),
        radial-gradient(38px circle at 19px 19px, #ddd 50%, transparent 51%),
        linear-gradient(#ddd, #ddd);
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}
.duo-form {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 100%;
    row-gap: 36px;
    margin-bottom: 32px;
}
.duo-form > * {
    flex-grow: 1;
}
.isolate-form {
    text-align: left;
}
.isolate-form label {
    /* color: var(--color-kt-blue); */
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
}
.isolate-form input:not([type="radio"]),
.isolate-form textarea {
    width: 100%;
}
.isolated-form input[type="radio"] {
    color: red;
    cursor: pointer;
    appearance: checkbox;
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox; /* Firefox */
    -ms-appearance: checkbox; /* not currently supported */
}
.isolate-form input[type="checkbox"] {
    width: unset !important;
}
.isolate-form > * + * {
    margin-top: 16px;
}
.isolate-form input:read-only,
.isolate-form textarea:read-only,
.isolate-form select[readonly] {
    background-color: #ddd !important;
}
.isolate-form select[readonly] {
    pointer-events: none;
}
.isolate-form.hide {
    display: none;
    /* visibility: hidden; */
}
.toggle-primary-container {
    background-color: #f3f6f9;
    border-radius: 4px;
    border: 1px #ddd solid;
    padding: 8px;
    user-select: none;
}
.toggle-primary-container > * + * {
    margin-top: 8px;
}
.toggle-primary-container > label {
    margin-bottom: 0;
    font-weight: 500;
    text-transform: initial;
}
select.placeholder {
    color: #ccc;
}
select {
    color: black;
}
.checkbox-container {
    display: flex !important;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.form-title {
    font-size: 24px;
    color: var(--color-kt-blue);
}
.decoration {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.decoration .prefix {
    pointer-events: none;
    user-select: none;
    position: absolute;
    padding-left: 12px;
    left: 0;
    margin-left: auto;
    color: #979797;
    font-weight: 600;
}
.decoration .prefix + input {
    text-align: right;
    padding-left: 36px;
}
.decoration .suffix {
    pointer-events: none;
    user-select: none;
    position: absolute;
    padding-right: 12px;
    right: 0;
    margin-left: auto;
    color: #979797;
    font-weight: 600;
}
.decoration .suffix + input {
    padding-right: 36px;
}
.address-detail-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
}
.address-detail-container > * {
    flex-grow: 1;
}
.contact-detail-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
}
.contact-detail-container > *:first-child {
    grid-column: span 2 / span 2;
}
.parcel-meta-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
}
.parcel-dimension-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
}
select {
    width: 100%;
}
.modal-container {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.modal-confirmation-message {
    font-weight: bold;
    font-size: 24px;
}
.scan-feedback,
.scan-input,
.manifest-modal {
    padding: 40px 0;
    margin: 0 40px;
}
.scan-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.scan-feedback + form {
    border-top: 1px solid #ccc;
}
.feedback-owner {
    font-size: 36px;
    font-weight: bolder;
}
.feedback-barcode {
    font-weight: 500;
    font-size: 17px;
}
.feedback-icon {
    font-size: 72px;
    color: var(--color-kt-red);
}
.feedback-heading {
    font-size: 24px;
    font-weight: bold;
}
.feedback-paragraph {
    font-size: 17px;
}
.scan-input label {
    display: block;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.scan-input input {
    min-width: 280px;
}
.scan-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    gap: 16px;
}
.scanner-input-warning {
    margin-top: 8px;
    font-size: 13px;
    text-align: left;
    color: var(--color-kt-red);
}
.closing .scanner-input-warning {
    display: none;
}
.form-action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    text-align: left;
}
.status-green {
    font-size: 17px;
    color: var(--color-kt-green);
    font-weight: bold;
}
.status-red {
    font-size: 17px;
    color: var(--color-kt-red);
    font-weight: bold;
}
.manifest-modal {
    text-align: left;
}
.manifest-modal label {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
}
.manifest-modal > * + * {
    margin-top: 16px;
}

@keyframes loading {
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}
@keyframes falldown {
    from {
        transform: translateY(-80px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes flyup {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-80px);
        opacity: 0;
    }
}

@media screen and (min-width: 600px) {
    :root {
        --container-size: 80%;
    }
    .sub-nav-button:hover {
        background-color: #eee;
    }
    .duo-form {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 36px;
    }
    .parcel-dimension-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
    }
}
@media screen and (min-width: 905px) {
    :root {
        --container-size: 840px;
    }
    .parcel-meta-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
    }
    .address-detail-container,
    .contact-detail-container,
    .parcel-dimension-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 16px;
    }
    /* .contact-detail-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    } */
}
@media screen and (min-width: 1240px) {
    :root {
        --container-size: 85%;
    }
    .duo-form {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 36px;
    }
}
@media screen and (min-width: 1440px) {
    :root {
        --container-size: 1240px;
    }
}
</style>
