<template>
    <MainLayout>
        <div class="container">
            <div class="go-back-container">
                <button class="blue-link" @click="goBack">BACK</button>
            </div>
            <div class="page-title-container">
                <div class="page-title">
                    New Invoice {{ data.__id ? "(Draft)" : "" }}
                </div>
                <!-- <button class="button yellow with-icon" :disabled="loading || true" @click="handleScan" >
                    <font-awesome-icon icon="barcode"></font-awesome-icon>
                     Import from Lineclear
                </button> -->
            </div>
            <span class="divider"></span>
            <InvoiceForm @onSubmit="handleGenerateInvoice" :data="data" />
            <span class="divider"></span>
            <div class="form-action-container">
                <button
                    class="button yellow-outline lg"
                    type="button"
                    @click="saveAsDraft"
                    :disabled="loading"
                >
                    {{ data.__id ? "Save Draft" : "Save as Draft" }}
                </button>
                <button
                    class="button yellow lg"
                    type="button"
                    :disabled="loading"
                    @click="submit"
                >
                    {{ loading ? "Generating..." : "Generate Invoice" }}
                </button>
            </div>
            <!-- </form> -->
        </div>

        <ModalLayout
            :isOpen="isFeedbackOpen"
            :isSkipEscapeKey="true"
            @onClose="closeFeedbackModal"
            @onRoot="setModalRef"
        >
            <InvoiceFeedbackModal
                @onClose="closeFeedbackModal"
                :print="print"
                :feedback="feedbackMessage"
                :invoiceId="feedbackTarget?.id || null"
            ></InvoiceFeedbackModal>
        </ModalLayout>
    </MainLayout>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import InvoiceFeedbackModal from "../components/modal/InvoiceFeedbackModal.vue"
import InvoiceForm from "../components/form/InvoiceForm.vue"
import {
    DRAFT_KEY_LOCALSTORAGE,
    getLocalStorageDataByKey,
    postApi,
    printInvoiceByID,
    randomId,
    setLoader,
    setLocalStorageData,
} from "../util/dodoo"
import { useRouter, useRoute } from "vue-router"
import { toast } from "../util/dodoo"

const defaultBilling = {
    company_name: "",
    name: "",
    contact: "",
    unit_no: "",
    address: "",
    postcode: "",
    state: "",
    city: "",
}

const defaultParcelDetail = {
    description: "",
    value: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    tracking_no: "",
}

const defaultRecipient = {
    is_using_invoice_address: true,
    ...defaultBilling,
    ...defaultParcelDetail,
}

// const citySuggestions = ref(new Map());

const router = useRouter()
const route = useRoute()

const isFeedbackOpen = ref(false)
const feedbackTarget = ref()
const feedbackMessage = ref()

// const mainFormRef = ref();
// const primaryFormRef = ref();
// const isUsePrimary = ref(true);

const modalRef = ref()

const data = ref({ ...defaultBilling, recipients: [{ ...defaultRecipient }] })
// const toastList = ref([]);
// const isScanModalOpen = ref(false);
const loading = ref(false)

const setModalRef = (r) => {
    modalRef.value = r
}

const closeFeedbackModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isFeedbackOpen.value = false
            },
            { once: true }
        )
    } else {
        isFeedbackOpen.value = false
    }
}

const sliceRecipientByName = (name) => {
    const regExp = /\[.*?\]/g
    const matches = name.match(regExp)
    if (matches) {
        return matches.map((m) => m.replace("[", "").replace("]", ""))
    }
    return null
}

const serializeNestedbject = (obj, name) => {
    let out = {}
    for (let k in obj) {
        const v = obj[k]
        if (Array.isArray(v)) {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}` : k}`),
            }
        } else if (typeof v === "object") {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}[${k}]` : k}`),
            }
        } else {
            out[`${name ? `${name}[${k}]` : k}`] = v
        }
    }
    return out
}

const handleGenerateInvoice = (e) => {
    setLoader(true)
    const payload = serializeNestedbject(e)
    const fd = new FormData()
    for (let k in payload) {
        if (k.includes("recipients")) {
            const [index, val] = sliceRecipientByName(k)
            if (payload[`recipients[${index}][is_using_invoice_address]`]) {
                fd.set(k, payload[val] || payload[k])
            } else {
                fd.append(k, payload[k])
            }
        } else {
            fd.append(k, payload[k])
        }
    }
    handleCreateNewInvoice(fd)
}

const goBack = () => {
    router.push("/?nav_key=draft")
}

const submit = () => {
    document.querySelector("#invoiceForm").requestSubmit()
}

const print = (invoice_id) => {
    printInvoiceByID(invoice_id)
}

const handleCreateNewInvoice = async (payload) => {
    loading.value = true
    const response = await postApi("/invoices/add", payload)
    loading.value = false
    if (response.error) {
        toast(response.error, "error")
    }
    if (response.id) {
        // If this is from a draft
        if (data.value.__id) {
            toast("Successfully Created from Draft")
        } else {
            toast("Successfully Created")
        }

        // Remove draft if there is any
        setLocalStorageData(DRAFT_KEY_LOCALSTORAGE, (d) => {
            const data_ = d || []
            return data_.filter((d) => d.__id !== data.value.__id)
        })

        // Success response
        feedbackTarget.value = response
        feedbackMessage.value = {
            title: "Invoice Created",
            desc: "Transaction has been recorded.",
        }
        isFeedbackOpen.value = true
    }
}

const handleSelectInitialAction = (e) => {
    if (e.target.tagName === "SELECT") {
        e.target.classList.remove("placeholder")
    }
}

const saveAsDraft = () => {
    // Progress calculation
    let a = 0
    let b = 0
    document.querySelectorAll("label[required]").forEach((el) => {
        let canIgnore = false
        const target = el.nextElementSibling.hasAttribute("required")
            ? el.nextSibling
            : el.nextSibling.querySelector("[required]")
        const [index] = target.name.includes("recipients")
            ? sliceRecipientByName(target.name)
            : []
        const isElementOptional =
            target.name.includes("recipients") &&
            document
                .querySelector(`#toggle-primary-container-${index}`)
                ?.nextSibling?.contains(target)

        if (isElementOptional) {
            console.log(index)
            canIgnore = data.value.recipients[index].is_using_invoice_address
        }
        if (!canIgnore) {
            a++
            if (target && target?.value?.length > 0) {
                b++
            }
        }
    })

    const progress = ((100 * b) / a).toFixed(2)

    const modifier = (draftsFromLocalStorage) => {
        const draft = draftsFromLocalStorage || []
        const existingDraft = data.value.__id
            ? draftsFromLocalStorage.find((d) => d.__id === data.value.__id)
            : null
        const __id = existingDraft ? existingDraft.__id : randomId(6)
        const createdAt = Date.now() || data.value.createdAt
        const modifiedAt = data.value.createdAt ? Date.now() : undefined
        const newData = { __id, createdAt, modifiedAt, ...data.value, progress }

        if (existingDraft) {
            console.log("Saving existing draft", __id)
            return draft
                .map((d) => (d.__id === __id ? newData : d))
                .sort(
                    (a, b) =>
                        (b.modifiedAt || b.createdAt) -
                        (a.modifiedAt || a.createdAt)
                )
        }
        draft.push(newData)
        return draft.sort(
            (a, b) =>
                (b.modifiedAt || b.createdAt) - (a.modifiedAt || a.createdAt)
        )
    }
    const [_, error] = setLocalStorageData(DRAFT_KEY_LOCALSTORAGE, modifier)
    if (!error) {
        _
        toast("Draft saved")
        return router.push("/?nav_key=draft")
    } else {
        console.warn(error)
        toast("Failed to save", "error")
    }
}

const handleDraftSyncOnMount = () => {
    const draftId = route.params.draft_id
    if (!draftId) {
        return router.replace("/create")
    }

    const drafts = getLocalStorageDataByKey(DRAFT_KEY_LOCALSTORAGE)
    if (!drafts) {
        return router.replace("/create")
    }

    const target = drafts.find((d) => d.__id === draftId)
    if (!target) {
        return router.replace("/create")
    }
    data.value = target
    // router.replace("/create");
}

onMounted(() => {
    // Dev_only
    window.add = () => {
        data.value.recipients.push({ ...defaultRecipient })
    }

    handleDraftSyncOnMount()
    document.addEventListener("change", handleSelectInitialAction)
})

onBeforeUnmount(() => {
    document.removeEventListener("change", handleSelectInitialAction)
})
</script>

<style scoped></style>
