<template>
    <div v-if="!props.hide" class="top-nav">
        <img class="top-nav-logo" src="/assets/img/dark-logo-only.png" />
        <button @click="logout">
            <font-awesome-icon
                icon="arrow-right-from-bracket"
            ></font-awesome-icon>
        </button>
    </div>
</template>

<script setup>
import { defineProps } from "vue"
import { useRouter } from "vue-router"
import { postApi } from "../util/dodoo"

const props = defineProps({
    hide: Boolean,
})

const router = useRouter()

const logout = async () => {
    const logoutResponse = await postApi("/api/auth/logout")
    if (logoutResponse.message) {
        localStorage.removeItem("access_token")
    }
    router.push({ path: "/login", query: { status: "logout" } })
}
</script>

<style scoped>
.top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 24px;
    background-color: var(--color-kt-blue);
    position: sticky;
    top: 0;
    z-index: 2;
    user-select: none;
}
.top-nav-logo {
    height: 90%;
    width: auto;
}
.top-nav button {
    color: var(--color-kt-yellow);
    font-size: 24px;
    background-color: transparent;
}
</style>
