<template>
    <div class="modal-container">
        <div>
            <div class="feedback-icon">
                <font-awesome-icon icon="circle-check"></font-awesome-icon>
            </div>
            <div class="feedback-title">
                {{ props.feedback?.title || "Feedback Title" }}
            </div>
            <div class="feedback-desc">
                {{ props.feedback?.desc || "Feedback Description" }}
            </div>
        </div>
        <span class="divider"></span>
        <div class="form-action-container">
            <button
                @click="goToList"
                class="button yellow-outline"
                ref="button"
            >
                Back to List
            </button>
            <button @click="props.print(props.invoiceId)" class="button yellow">
                Print
            </button>
        </div>
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue"
import { useRouter } from "vue-router"

const props = defineProps({
    feedback: Object,
    invoiceId: Number,
    print: Function,
})

const router = useRouter()

const button = ref()

// const emit = defineEmits(["onClose"]);

// const handleClose = () => {
//     emit("onClose");
// }

const goToList = () => {
    router.push("/")
}

onMounted(() => {
    if (button.value) {
        button.value.focus()
    }
})
</script>

<style scoped>
.modal-container {
    padding: 30px;
}
.feedback-icon {
    font-size: 80px;
    color: var(--color-kt-green);
}
.feedback-title {
    font-size: 30px;
}
.feedback-desc {
    font-size: 17px;
}
</style>
