<template>
    <MainLayout>
        <div class="container">
            <div class="go-back-container">
                <button class="blue-link" @click="goBack">BACK</button>
            </div>
            <div class="page-title-container">
                <div class="page-title">
                    New Invoice {{ data.__id ? "(Draft)" : "" }}
                </div>
                <!-- <button class="button yellow with-icon" :disabled="loading || true" @click="handleScan" >
                    <font-awesome-icon icon="barcode"></font-awesome-icon>
                     Import from Lineclear
                </button> -->
            </div>
            <span class="divider"></span>
            <!-- <form @submit="handleGenerateInvoice" ref="mainFormRef"> -->
            <InvoiceForm @onSubmit="handleGenerateInvoice" :data="data" />
            <!-- <div class="duo-form">
                <div class="isolate-form" ref="primaryFormRef">
                    <div class="form-title">
                        Invoice to
                    </div>
                    <div>
                        <label>Company Name</label>
                        <input @input="handlePrimaryForm_($event)" placeholder="Company Name" type="text" name="company_name" :value="data.company_name" />
                    </div>
                    <div class="contact-detail-container">
                        <div>
                            <label required>Name</label>
                            <input @input="handlePrimaryForm_($event)" placeholder="Name" required minlength="5" type="text" name="name" :value="data.name" />
                        </div>
                        <div>
                            <label required>Contact No</label>
                            <input @input="handlePrimaryForm_($event)" placeholder="0123456789" type="tel" required minlength="10" name="contact" :value="data.contact"/>
                        </div>
                    </div>
                    <div>
                        <label required>Unit No/Building</label>
                        <input @input="handlePrimaryForm_($event)" type="text" required placeholder="Unit No, Building" name="unit_no" :value="data.unit_no" />
                    </div>
                    <div>
                        <label required>Address</label>
                        <textarea rows="4" @input="handlePrimaryForm_($event)" placeholder="Unit No, Building, Street, Town" required minlength="6" name="address"  :value="data.address"></textarea>
                    </div>
                    <div class="address-detail-container">
                        <div>
                            <label required>Postcode</label>
                            <input @input="handlePrimaryForm_($event)" required minlength="5" placeholder="00000" maxlength="5" type="number" inputmode="numeric" pattern="\d*" name="postcode" :value="data.postcode" />
                        </div>
                        <div>
                            <label required>State</label>
                            <select class="placeholder" name="state" required @change="handlePrimaryForm_($event)" :value="data.state">
                                <option hidden value="">State</option>
                                <option v-for="state, i in STATES_MY" :key="i" :value="state">{{ state }}</option>
                            </select>
                        </div>
                        <div>
                            <label required>City</label>
                            <input list="cityList" @input="handlePrimaryForm_($event)" required type="text" placeholder="City" name="city" :value="data.city" />
                            <datalist id="cityList" v-if="citySuggestions.get('city')">
                                <option  v-for="city, i in citySuggestions.get('city')" :key="i" :value="city"></option>
                            </datalist>
                        </div>
                    </div>
                </div>
                <template v-for="d, i in data.recipients" :key="i" >
                    <div class="isolate-form">
                        <div class="form-title">
                            Deliver to
                        </div>
                        <div class="toggle-primary-container">
                            <label class="checkbox-container">
                                <input type="radio" name="reuse"  @change="toggleUsePrimary($event)" value="on" :checked="isUsePrimary" />
                                <span>Same as invoice address</span>
                            </label>
                            <label class="checkbox-container">
                                <input type="radio" name="reuse" @change="toggleUsePrimary($event)" value="off" :checked="!isUsePrimary" />
                                <span>Use a different address</span>
                            </label>
                        </div>
                        <div v-if="data.recipients?.[0]" :class="`isolate-form ${ isUsePrimary ? 'hide' : '' }`">
                            <div>
                                <label>Company Name</label>
                                <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" type="text" placeholder="Company Name" name="recipients[0][company_name]" :value="d['company_name']"  />
                            </div>
                            <div class="contact-detail-container">
                                <div>
                                    <label required>Name</label>
                                    <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" required minlength="5" type="text" placeholder="Name" name="recipients[0][name]" :value="d['name']"  />
                                </div>
                                <div>
                                    <label required>Contact No</label>
                                    <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" type="tel" required minlength="10" placeholder="0123456789" name="recipients[0][contact]" :value="d['contact']"/>
                                </div>
                            </div>
                            <div>
                                <label required>Unit No/Building</label>
                                <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" type="text" required placeholder="Unit No, Building" name="recipients[0][unit_no]" :value="d['unit_no']" />
                            </div>
                            <div>
                                <label required>Address</label>
                                <textarea :disabled="isUsePrimary" @input="handleRecipientForm_($event)" rows="4" required minlength="6" placeholder="Unit No, Building, Street, Town" name="recipients[0][address]" :value="d['address']"></textarea>
                            </div>
                            <div class="address-detail-container">
                                <div>
                                    <label required>Postcode</label>
                                    <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" required minlength="5" maxlength="5" type="number" placeholder="00000" inputmode="numeric" pattern="\d*" name="recipients[0][postcode]" :value="d['postcode']"  />
                                </div>
                                <div>
                                    <label required>State</label>
                                    <select :disabled="isUsePrimary" class="placeholder" required name="recipients[0][state]" :value="d['state']">
                                        <option hidden value="">State</option>
                                        <option v-for="state, i in STATES_MY" :key="i" :value="state">{{ state }}</option>
                                    </select>
                                </div>
                                <div>
                                    <label required>City</label>
                                    <input :disabled="isUsePrimary" @input="handleRecipientForm_($event)" :list="`cityList[0]`" required type="text" name="recipients[0][city]" :value="d['city']" placeholder="City" />
                                    <datalist :id="`cityList[0]`" v-if="citySuggestions.get(`recipients[0][city]`)">
                                        <option  v-for="city, i in citySuggestions.get(`recipients[0][city]`)" :key="i" :value="city"></option>
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="isolate-form">
                        <div class="form-title">
                            Parcel Detail
                        </div>
                        <div class="parcel-meta-container">
                            <div>
                                <label required>Item Description</label>
                                <input @input="handleRecipientForm_($event)" :value="d['item_description']" required type="text" name="recipients[0][item_description]" placeholder="Clothes" />
                            </div>
                            <div>
                                <label required>Item Value</label>
                                <div class="decoration">
                                    <span class="prefix">RM</span>
                                    <input @input="handleRecipientForm_($event)" :value="d['value']" required type="number" min="0.01" step="0.01" name="recipients[0][value]" placeholder="0.00"/>
                                </div>
                            </div>
                            <div>
                                <label required>Parcel Weight</label>
                                <div class="decoration">
                                    <span class="suffix">KG</span>
                                    <input @input="handleRecipientForm_($event)" :value="d['weight']" required type="number" min="0.01" step="0.01" name="recipients[0][weight]" placeholder="0.00"/>
                                </div>
                            </div>
                        </div>
                        <div class="parcel-dimension-container">
                            <div>
                                <label>Width</label>
                                <div class="decoration">
                                    <span class="suffix">CM</span>
                                    <input @input="handleRecipientForm_($event)" :value="d['width']" type="number" min="0.1" step="0.1" name="recipients[0][width]" placeholder="0.0"/>
                                </div>
                            </div>
                            <div>
                                <label>Height</label>
                                <div class="decoration">
                                    <span class="suffix">CM</span>
                                    <input @input="handleRecipientForm_($event)" :value="d['height']" type="number" min="0.1" step="0.1" name="recipients[0][height]" placeholder="0.0"/>
                                </div>
                            </div>
                            <div>
                                <label>Length</label>
                                <div class="decoration">
                                    <span class="suffix">CM</span>
                                    <input @input="handleRecipientForm_($event)" :value="d['length']" type="number" min="0.1" step="0.1" name="recipients[0][length]" placeholder="0.0"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Tracking No</label>
                            <input @input="handleRecipientForm_($event)" :value="d['tracking_no']" type="text" name="recipients[0][tracking_no]" placeholder="Tracking No"/>
                        </div>
                    </div>
                </template>
            </div> -->
            <span class="divider"></span>
            <div class="form-action-container">
                <button
                    class="button yellow-outline lg"
                    type="button"
                    @click="saveAsDraft"
                    :disabled="loading"
                >
                    {{ data.__id ? "Save Draft" : "Save as Draft" }}
                </button>
                <button
                    class="button yellow lg"
                    type="button"
                    @click="submit"
                    :disabled="loading"
                >
                    {{ loading ? "Generating..." : "Generate Invoice" }}
                </button>
            </div>
            <!-- </form> -->
        </div>

        <ModalLayout
            :isOpen="isFeedbackOpen"
            :isSkipEscapeKey="true"
            @onClose="closeFeedbackModal"
            @onRoot="setModalRef"
        >
            <InvoiceFeedbackModal
                @onClose="closeFeedbackModal"
                :print="print"
                :feedback="feedbackMessage"
                :invoiceId="feedbackTarget?.id || null"
            ></InvoiceFeedbackModal>
        </ModalLayout>
    </MainLayout>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import InvoiceFeedbackModal from "../components/modal/InvoiceFeedbackModal.vue"
import InvoiceForm from "../components/form/InvoiceForm.vue"
import {
    DRAFT_KEY_LOCALSTORAGE,
    postApi,
    printInvoiceByID,
    randomId,
    setLoader,
    setLocalStorageData,
} from "../util/dodoo"
import { useRouter } from "vue-router"
import { toast } from "../util/dodoo"

const defaultBilling = {
    company_name: "",
    name: "",
    contact: "",
    unit_no: "",
    address: "",
    postcode: "",
    state: "",
    city: "",
}

const defaultParcelDetail = {
    description: "",
    value: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    tracking_no: "",
}

const defaultRecipient = {
    is_using_invoice_address: true,
    ...defaultBilling,
    ...defaultParcelDetail,
}
// const citySuggestions = ref(new Map());

const router = useRouter()

const isFeedbackOpen = ref(false)
const feedbackTarget = ref()
const feedbackMessage = ref()

// const mainFormRef = ref();
// const primaryFormRef = ref();
// const isUsePrimary = ref(true);

const modalRef = ref()

const data = ref({ ...defaultBilling, recipients: [{ ...defaultRecipient }] })
// const toastList = ref([]);
// const isScanModalOpen = ref(false);
const loading = ref(false)

const setModalRef = (r) => {
    modalRef.value = r
}

const closeFeedbackModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isFeedbackOpen.value = false
            },
            { once: true }
        )
    } else {
        isFeedbackOpen.value = false
    }
}

const sliceRecipientByName = (name) => {
    const regExp = /\[.*?\]/g
    const matches = name.match(regExp)
    if (matches) {
        return matches.map((m) => m.replace("[", "").replace("]", ""))
    }
    return null
}

const serializeNestedbject = (obj, name) => {
    let out = {}
    for (let k in obj) {
        const v = obj[k]
        if (Array.isArray(v)) {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}` : k}`),
            }
        } else if (typeof v === "object") {
            out = {
                ...out,
                ...serializeNestedbject(v, `${name ? `${name}[${k}]` : k}`),
            }
        } else {
            out[`${name ? `${name}[${k}]` : k}`] = v
        }
    }
    return out
}

const handleGenerateInvoice = (e) => {
    setLoader(true)
    const payload = serializeNestedbject(e)
    const fd = new FormData()
    for (let k in payload) {
        if (k.includes("recipients")) {
            const [index, val] = sliceRecipientByName(k)
            if (payload[`recipients[${index}][is_using_invoice_address]`]) {
                fd.set(k, payload[val] || payload[k])
            } else {
                fd.append(k, payload[k])
            }
        } else {
            fd.append(k, payload[k])
        }
    }
    handleCreateNewInvoice(fd)
}

const goBack = () => {
    router.push("/")
}

const submit = () => {
    document.querySelector("#invoiceForm").requestSubmit()
}

const print = (invoice_id) => {
    printInvoiceByID(invoice_id)
}

const handleCreateNewInvoice = async (payload) => {
    loading.value = true
    const response = await postApi("/invoices/add", payload)
    loading.value = false
    if (response.error) {
        toast(response.error, "error")
    }
    if (response.id) {
        // If this is from a draft
        if (data.value.__id) {
            toast("Successfully Created from Draft")
        } else {
            toast("Successfully Created")
        }

        // Remove draft if there is any
        setLocalStorageData(DRAFT_KEY_LOCALSTORAGE, (d) => {
            const data_ = d || []
            return data_.filter((d) => d.__id !== data.value.__id)
        })

        // Success response
        feedbackTarget.value = response
        feedbackMessage.value = {
            title: "Invoice Created",
            desc: "Transaction has been recorded.",
        }
        isFeedbackOpen.value = true
    }
}

const handleSelectInitialAction = (e) => {
    if (e.target.tagName === "SELECT") {
        e.target.classList.remove("placeholder")
    }
}

const saveAsDraft = () => {
    // Progress calculation
    let a = 0
    let b = 0
    document.querySelectorAll("label[required]").forEach((el) => {
        a++
        if (
            el.nextElementSibling &&
            el.nextElementSibling.value &&
            el.nextElementSibling.value.length > 0
        ) {
            b++
        }
    })

    const modifier = (draftsFromLocalStorage) => {
        const draft = draftsFromLocalStorage || []
        const existingDraft = data.value.__id
            ? draftsFromLocalStorage.find((d) => d.__id === data.value.__id)
            : null
        const __id = existingDraft ? existingDraft.__id : randomId(6)
        const createdAt = Date.now() || data.value.createdAt
        const modifiedAt = data.value.createdAt ? Date.now() : undefined
        const progress = ((100 * b) / a).toFixed(2)
        const newData = { __id, createdAt, modifiedAt, ...data.value, progress }
        if (existingDraft) {
            console.log("Saving existing draft", __id)
            return draft
                .map((d) => (d.__id === __id ? newData : d))
                .sort(
                    (a, b) =>
                        (b.modifiedAt || b.createdAt) -
                        (a.modifiedAt || a.createdAt)
                )
        }
        console.log("Saving new draft", __id)

        draft.push(newData)
        return draft.sort(
            (a, b) =>
                (b.modifiedAt || b.createdAt) - (a.modifiedAt || a.createdAt)
        )
    }
    const [_, error] = setLocalStorageData(DRAFT_KEY_LOCALSTORAGE, modifier)
    if (!error) {
        _
        toast("Draft saved")
        return router.push("/?nav_key=draft")
    } else {
        console.warn(error)
        toast("Failed to save", "error")
    }
}

onMounted(() => {
    // Dev_only
    window.add = () => {
        data.value.recipients.push({ ...defaultRecipient })
    }

    document.addEventListener("change", handleSelectInitialAction)
})

onBeforeUnmount(() => {
    document.removeEventListener("change", handleSelectInitialAction)
})
</script>

<style scoped></style>
