<template>
    <Teleport to="#modal">
        <div v-if="props.isOpen" class="modal-wrapper" ref="modalRef">
            <div :class="`modal-content ${props.size || ''}`">
                <slot></slot>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeUnmount } from "vue"
const props = defineProps({
    isOpen: Boolean,
    size: String,
    isSkipEscapeKey: Boolean,
})

const emit = defineEmits(["onClose", "onRoot"])

const modalRef = ref()

const handleClose = () => {
    emit("onClose")
}

const handleModalKey = (e) => {
    if (props.isSkipEscapeKey) {
        return
    }
    switch (e.key) {
        case "Escape":
            handleClose()
            break

        default:
            break
    }
}

onMounted(() => {
    document.addEventListener("keydown", handleModalKey)
    emit("onRoot", modalRef)
})

onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleModalKey)
})
</script>

<style>
.modal-wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.15);
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.modal-content {
    width: 100%;
    max-width: 360px;
    max-height: 80vh;
    /* overflow: scroll; */
    margin: 0 auto;
    animation: falldown 250ms;
    overflow-y: auto;
}
.modal-content.lg {
    max-width: 640px;
}
.closing .modal-content {
    animation: flyup 200ms;
}
</style>
