// import { postApi } from "../util/dodoo";
import { createRouter, createWebHistory } from "vue-router"
import Login from "../views/Login.vue"
import CreateInvoice from "../views/CreateInvoice.vue"
import InvoiceList from "../views/InvoiceList.vue"
import ViewInvoice from "../views/ViewInvoice.vue"
import DraftInvoice from "../views/DraftInvoice.vue"

const routes = [
    {
        path: "/",
        name: "invoices",
        component: InvoiceList,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/create",
        name: "createInvoice",
        component: CreateInvoice,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/draft/:draft_id",
        name: "draftInvoice",
        component: DraftInvoice,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next()
            } else {
                next({
                    path: "/",
                    replace: true,
                })
            }
        },
    },
    {
        path: "/invoice/:invoice_id",
        name: "invoice",
        component: ViewInvoice,
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem("access_token")) {
                next({
                    path: "/login",
                    replace: true,
                })
            } else {
                next()
            }
        },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/login",
    },
]

const router = createRouter({
    history: createWebHistory("/"),
    routes,
})

export default router
