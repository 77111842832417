<template>
    <MainLayout>
        <div class="container">
            <div class="page-title-container">
                <div class="page-title">Invoice</div>
                <div class="page-title-action">
                    <div
                        class="btn-dropdown-container"
                        ref="downloadDropdownRef"
                    >
                        <button
                            class="button yellow-outline"
                            @click="toggleDownloadDropdown"
                            :disabled="loading"
                        >
                            <span>Download</span>
                            <font-awesome-icon
                                icon="chevron-down"
                            ></font-awesome-icon>
                        </button>
                        <ul
                            v-if="showDownloadDropdown"
                            class="btn-dropdown-menu"
                        >
                            <li
                                tabindex="0"
                                @click="openManifestModal('manifest')"
                            >
                                Manifest
                            </li>
                            <li
                                tabindex="0"
                                @click="openManifestModal('bulk-order')"
                            >
                                Bulk Order Excel
                            </li>
                        </ul>
                    </div>
                    <button
                        class="button yellow"
                        @click="createInvoice"
                        :disabled="loading"
                    >
                        New Invoice
                    </button>
                </div>
                <!-- <CsvUploader inputId="upload-csv" :disabled="loading || isUploading" @onUpload="handleUploadCSV"></CsvUploader> -->
                <!-- <input type="file" id="upload-csv" name="csv" @change="handleUploadCSV" accept=".csv" hidden="hidden"/> -->
            </div>

            <div class="action-container">
                <form @submit.prevent="search">
                    <!-- <input class="search-input" type="search" v-model="searchTerm" placeholder="Search" /> -->
                    <input
                        class="search-input"
                        type="text"
                        v-model="searchTerm"
                        placeholder="Search"
                        @keydown="handleSearchKeyDown"
                    />
                </form>
                <button
                    v-if="isShowSearchResult"
                    class="red-link"
                    @click="clearSearch"
                >
                    Clear
                </button>
            </div>
            <div v-if="searchResultTerm && !loading" class="search-description">
                {{
                    total > 0
                        ? `Found ${total} result${total > 1 ? "s" : ""}`
                        : "No result found"
                }}
                for "{{ searchResultTerm }}".
            </div>

            <!-- <div class="exp-sub-nav">
                <div class="exp-sub-nav-button-container">
                    <button :class="{'sub-nav-button': true, 'active': currentSubNav.name === subNav.name}" @click="handleSubNav(subNav)" v-for="subNav in subNavs" :key="subNav.name" :disabled="loading" >
                    {{subNav.name}}
                    <span v-if="!subNav.skipBubble && bubble" class="sub-nav-bubble">
                        {{bubble[subNav.bubbleKey]}}
                    </span>
                </button>
                </div>
            </div> -->

            <div v-if="!isShowSearchResult" class="sub-nav">
                <button
                    :class="{
                        'sub-nav-button': true,
                        active: currentSubNav.name === subNav.name,
                    }"
                    @click="handleSubNav(subNav)"
                    v-for="subNav in subNavs"
                    :key="subNav.name"
                    :disabled="loading"
                >
                    {{ subNav.name }}
                    <span
                        v-if="
                            !subNav.skipBubble &&
                            bubble &&
                            bubble[subNav.bubbleKey]
                        "
                        class="sub-nav-bubble"
                    >
                        {{ bubble[subNav.bubbleKey] }}
                    </span>
                </button>
            </div>

            <div class="table-container">
                <div v-if="!loading">
                    <template v-if="data && data.length">
                        <div class="table-scollable">
                            <table
                                v-if="currentSubNav.key === 'draft'"
                                class="table"
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Created At</th>
                                        <th>Progress</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(d, i) in data" :key="d.__id">
                                        <td>{{ page * LIMIT + i + 1 }}</td>
                                        <td>{{ d.name || "-" }}</td>
                                        <td>
                                            {{
                                                new Date(d.createdAt)
                                                    .toJSON()
                                                    .slice(0, 10)
                                            }}
                                        </td>
                                        <td>
                                            <ProgressBar
                                                :progress="
                                                    Number(d.progress || 0)
                                                "
                                            />
                                            <!-- {{d.progress || 0}} % -->
                                        </td>
                                        <td>
                                            <div class="table-action-container">
                                                <button
                                                    class="button blue"
                                                    @click="draft(d.__id)"
                                                >
                                                    <font-awesome-icon
                                                        icon="pen"
                                                    ></font-awesome-icon>
                                                    <span>Edit</span>
                                                </button>
                                                <button
                                                    class="button red-outline"
                                                    @click="
                                                        handleDeleteButtonClick(
                                                            d.__id
                                                        )
                                                    "
                                                >
                                                    <font-awesome-icon
                                                        icon="trash-can"
                                                    ></font-awesome-icon>
                                                    <span>Delete</span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Invoice No.</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice To</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(d, i) in data" :key="d.id">
                                        <td>{{ page * LIMIT + i + 1 }}</td>
                                        <td>{{ d.invoice_no }}</td>
                                        <td>{{ d.created_at.slice(0, 10) }}</td>
                                        <td>{{ d.company_name || d.name }}</td>
                                        <td
                                            :class="
                                                d.status === 'Paid'
                                                    ? 'status-green'
                                                    : 'status-red'
                                            "
                                        >
                                            {{ d.status }}
                                        </td>
                                        <td>
                                            <div class="table-action-container">
                                                <template
                                                    v-if="d.status === 'Paid'"
                                                >
                                                    <button
                                                        v-if="
                                                            !d.version_history ||
                                                            !d.version_history
                                                                .length
                                                        "
                                                        class="button blue-outline"
                                                        style="padding-right: 0"
                                                        @click="view(d.id)"
                                                    >
                                                        <font-awesome-icon
                                                            icon="pen"
                                                        ></font-awesome-icon>
                                                        <span>Amend</span>
                                                        <span
                                                            class="dropdown-chevron"
                                                        >
                                                        </span>
                                                    </button>
                                                    <div
                                                        v-else
                                                        :class="`btn-dropdown blue-outline ${
                                                            i === dropdownIndex
                                                                ? 'show'
                                                                : ''
                                                        }`"
                                                    >
                                                        <button
                                                            class="dropdown-button"
                                                            @click="view(d.id)"
                                                        >
                                                            <font-awesome-icon
                                                                icon="pen"
                                                            ></font-awesome-icon>
                                                            <span>Amend</span>
                                                            <span
                                                                class="dropdown-chevron"
                                                                @click="
                                                                    openDropdown(
                                                                        $event,
                                                                        i
                                                                    )
                                                                "
                                                            >
                                                                <font-awesome-icon
                                                                    icon="chevron-down"
                                                                ></font-awesome-icon>
                                                            </span>
                                                        </button>
                                                        <button
                                                            class="btn-dropdown-selection button blue-outline"
                                                            @click="
                                                                version([
                                                                    d,
                                                                    ...d.version_history,
                                                                ])
                                                            "
                                                        >
                                                            <span
                                                                >Versioning</span
                                                            >
                                                        </button>
                                                    </div>
                                                </template>
                                                <button
                                                    class="button blue"
                                                    @click="print(d.id)"
                                                >
                                                    <font-awesome-icon
                                                        icon="print"
                                                    ></font-awesome-icon>
                                                    <span>Print</span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination-container">
                            <div class="pagination-detail">
                                {{ page * LIMIT + 1 }} -
                                {{ Math.min(total, (page + 1) * LIMIT) }} of
                                {{ total }}
                            </div>
                            <div class="pagination-button-container">
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goStart"
                                >
                                    {{ "<<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="page === 0"
                                    @click="pagination.goPrevious"
                                >
                                    {{ "<" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page ===
                                        Math.max(
                                            Math.floor((total - 1) / LIMIT),
                                            0
                                        )
                                    "
                                    @click="pagination.goNext"
                                >
                                    {{ ">" }}
                                </button>
                                <button
                                    class="pagination-button"
                                    :disabled="
                                        page ===
                                        Math.max(
                                            Math.floor((total - 1) / LIMIT),
                                            0
                                        )
                                    "
                                    @click="pagination.goEnd"
                                >
                                    {{ ">>" }}
                                </button>
                            </div>
                        </div>
                    </template>
                    <div v-else class="table-no-data">
                        <font-awesome-icon
                            icon="circle-question"
                        ></font-awesome-icon>
                        NO DATA
                    </div>
                </div>
                <div v-else class="loading-animation">
                    <font-awesome-icon icon="spinner"></font-awesome-icon>
                </div>
            </div>
        </div>

        <ModalLayout
            :isOpen="isVersionOpen"
            @onClose="closeVersionModal"
            @onRoot="setModalRef"
            size="lg"
        >
            <VersionModal
                @onClose="closeVersionModal"
                :print="print"
                :targetInvoice="versionTarget"
            ></VersionModal>
        </ModalLayout>
        <ModalLayout
            :isOpen="showManifestModal"
            @onClose="closeManifestModal"
            @onRoot="setManifestModalRef"
        >
            <ManifestRequestModal
                :type="manifestType"
                @onClose="closeManifestModal"
                @onSubmit="requestManifest"
            ></ManifestRequestModal>
        </ModalLayout>
        <ModalLayout
            :isOpen="showDeleteModal"
            @onClose="closeDeletingModal"
            @onRoot="setDeletingModalRef"
        >
            <DeleteModal
                :message="'Are you sure to delete this draft?'"
                :confirmMessage="'Delete'"
                @onClose="closeDeletingModal"
                @onSubmit="deleteDraft"
            ></DeleteModal>
        </ModalLayout>
    </MainLayout>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from "vue"
import MainLayout from "../components/layout/MainLayout.vue"
import ModalLayout from "../components/layout/ModalLayout.vue"
import VersionModal from "../components/modal/VersionModal.vue"
import ManifestRequestModal from "../components/modal/ManifestRequestModal.vue"
import DeleteModal from "../components/modal/DeleteModal.vue"
import ProgressBar from "../components/ProgressBar.vue"
import {
    DRAFT_KEY_LOCALSTORAGE,
    getApi,
    getLocalStorageDataByKey,
    printInvoiceByID,
    setLocalStorageData,
    toast,
} from "../util/dodoo"
import { useRouter, useRoute } from "vue-router"

const LIMIT = 20
const router = useRouter()
const route = useRoute()

const subNavs = [
    { name: "Paid", key: "paid", bubbleKey: "_" },
    { name: "Cancelled", key: "canceled", bubbleKey: "_" },
    { name: "Draft", key: "draft", bubbleKey: "draft" },
    { name: "All", key: "all", bubbleKey: "_", skipBubble: true },
]

const isVersionOpen = ref(false)

const data = ref()
const total = ref()
const bubble = ref()
const dropdownIndex = ref()

const modalRef = ref()
const downloadDropdownRef = ref()
const showDownloadDropdown = ref(false)

const manifestModalRef = ref()
const manifestType = ref()
const showManifestModal = ref(false)

const deletingTarget = ref()
const deletingModalRef = ref()
const showDeleteModal = ref(false)

const searchTerm = ref()
const page = ref(0)
const versionTarget = ref()
const loading = ref(true)
const currentSubNav = ref(subNavs[0])
const isShowSearchResult = ref(false)
const searchResultTerm = ref()

const setModalRef = (r) => {
    modalRef.value = r
}
const setManifestModalRef = (r) => {
    manifestModalRef.value = r
}

const closeVersionModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                isVersionOpen.value = false
            },
            { once: true }
        )
    } else {
        isVersionOpen.value = false
    }
}

const closeManifestModal = () => {
    const wrapper = modalRef.value.value
    if (wrapper) {
        wrapper.classList.add("closing")
        wrapper.addEventListener(
            "animationend",
            () => {
                showManifestModal.value = false
            },
            { once: true }
        )
    } else {
        showManifestModal.value = false
    }
}

const handleSubNav = (subN) => {
    page.value = 0

    if (searchTerm.value || subN.key !== currentSubNav.value.key) {
        searchTerm.value = ""
        currentSubNav.value = subN
        fetchData()
    }
}

const search = () => {
    if (loading.value) {
        return
    }

    page.value = 0

    // Handle empty search
    if (!searchTerm.value || !searchTerm.value.trim()) {
        clearSearch()
        return
    }

    // Handle search value
    searchResultTerm.value = searchTerm.value?.trim()
    isShowSearchResult.value = true
    fetchData()
}

const handleSearchKeyDown = (e) => {
    switch (e.key) {
        case "Escape":
            if (
                isShowSearchResult.value ||
                searchResultTerm.value ||
                searchTerm.value
            ) {
                clearSearch()
            } else {
                fetchData()
            }
            break

        default:
            break
    }
}

const clearSearch = () => {
    isShowSearchResult.value = false
    searchTerm.value = ""
    searchResultTerm.value = null
    page.value = 0
    fetchData()
}

const pagination = {
    goStart: () => {
        page.value = 0
        fetchData()
    },
    goPrevious: () => {
        page.value = Math.max(page.value - 1, 0)
        fetchData()
    },
    goNext: () => {
        page.value = Math.min(page.value + 1, Math.floor(total.value / LIMIT))
        fetchData()
    },
    goEnd: () => {
        page.value = Math.floor(total.value / LIMIT)
        fetchData()
    },
}

const print = (invoice_id) => {
    printInvoiceByID(invoice_id)
}

const createInvoice = () => {
    router.push("/create")
}

const openDropdown = (e, i) => {
    e.stopPropagation()
    dropdownIndex.value = i === dropdownIndex.value ? null : i
}

const toggleDownloadDropdown = () => {
    showDownloadDropdown.value = !showDownloadDropdown.value
}

const view = (invoice_id) => {
    router.push(`/invoice/${invoice_id}`)
}

const version = async (invoice_history) => {
    if (invoice_history) {
        versionTarget.value = invoice_history
        // versionTarget.value = result.data.concat(result.data).concat(result.data).concat(result.data);
        isVersionOpen.value = true
        dropdownIndex.value = null
    } else {
        toast("Invalid invoice history", "error")
    }
}

const setDeletingModalRef = (r) => {
    deletingModalRef.value = r
}

const handleDeleteButtonClick = (draftId) => {
    deletingTarget.value = draftId
    showDeleteModal.value = true
}

const closeDeletingModal = () => {
    showDeleteModal.value = false
}

const deleteDraft = () => {
    const isExist = data.value.find((d) => d.__id == deletingTarget.value)
    if (isExist) {
        const modifier = (d) => {
            return d.filter((e) => e.__id !== deletingTarget.value)
        }
        const [result, error] = setLocalStorageData(
            DRAFT_KEY_LOCALSTORAGE,
            modifier
        )
        if (error) {
            toast("Failed to delete", "error")
            return
        }
        toast("Successfully deleted")
        data.value = result
        bubble.value = result.length
        total.value = result.length
    }
    closeDeletingModal()
}

const openManifestModal = (type) => {
    showDownloadDropdown.value = false
    showManifestModal.value = true
    manifestType.value = type
}

const requestManifest = ({ payload, cb }) => {
    const base = `${
        location.hostname === "localhost" ? "http://localhost:8000" : ""
    }`
    const a = document.createElement("A")
    a.href = `${base}/invoices/download?start=${payload.start}&end=${
        payload.end
    }&type=${manifestType.value}&token=${localStorage.getItem("access_token")}`
    a.download = "KTExpress Manifest"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    cb()
}

const generateApiUrl = () => {
    const query = {
        search: searchTerm.value?.trim() || undefined,
        page: page.value,
    }
    let queryString = []
    for (let q in query) {
        if (query[q] !== undefined) {
            queryString.push(
                `${encodeURIComponent(q)}=${encodeURIComponent(query[q])}`
            )
        }
    }
    // Target all when searching
    const base = `/invoices/list/${
        query.search ? "all" : currentSubNav.value.key
    }`
    return `${base}?${queryString.join("&")}`
}

const fetchData = async () => {
    let result
    if (currentSubNav.value.key === "draft") {
        // LocalStorage fetch
        result = fetchDraftData()
    } else {
        // API fetch
        loading.value = true
        const url = generateApiUrl()
        result = await getApi(url)
        if (result.unauth) {
            router.push({ path: "/login", query: { status: "unauth" } })
            return
        }
        if (!result || result.error) {
            toast(result.error || "Error fetching data ", "error")
            return
        }
        result.bubble = result.bubble || {}
        result.bubble.draft = (
            getLocalStorageDataByKey(DRAFT_KEY_LOCALSTORAGE) || []
        ).length
    }

    bubble.value = result.bubble
    total.value = result.total
    data.value = result.data
    loading.value = false
}

const fetchDraftData = () => {
    loading.value = true
    let draft_invoices = getLocalStorageDataByKey(DRAFT_KEY_LOCALSTORAGE) || []
    let draft_total = draft_invoices?.length || 0
    return {
        bubble: { draft: draft_total },
        total: draft_total,
        data: draft_invoices,
    }
}

const handleClickOutsideDropdown = (e) => {
    const isClickedInside = document
        .querySelector(".btn-dropdown.show")
        ?.contains(e.target)
    if (!isClickedInside) {
        dropdownIndex.value = null
    }
    const isClickedInsideDownloadMenu = downloadDropdownRef.value?.contains(
        e.target
    )
    if (!isClickedInsideDownloadMenu) {
        showDownloadDropdown.value = false
    }
}

const draft = (draftId) => {
    // console.log({draftId})
    router.push(`/draft/${draftId}`)
}

const handleNavKey = () => {
    const nk = route.query.nav_key
    if (nk) {
        router.replace("/")
        const sel_nav = subNavs.find((n) => n.key === nk)
        if (sel_nav) {
            currentSubNav.value = sel_nav
        }
    }
}

onMounted(() => {
    handleNavKey()
    fetchData()
    document.addEventListener("mousedown", handleClickOutsideDropdown)
})

onBeforeUnmount(() => {
    document.removeEventListener("mousedown", handleClickOutsideDropdown)
})
</script>

<style scoped>
.upload-overlay {
    display: none;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    margin-top: var(--top-nav-height);
    padding: 32px;
}
.upload-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 3px dashed white;
    color: white;
}
.upload-text {
    font-size: 40px;
    margin-top: 24px;
}
</style>
