<template>
    <div class="modal-container">
        <form class="manifest-modal" @submit="handleConfirm($event)">
            <div class="page-title">
                Export
                {{ props.type === "manifest" ? "Manifest" : "Bulk Order" }}
            </div>
            <div>
                <label required> From Date </label>
                <input
                    required
                    type="date"
                    name="start"
                    @input="handlePayload($event)"
                    :value="payload.start"
                />
            </div>
            <div>
                <label required> To Date </label>
                <input
                    required
                    type="date"
                    name="end"
                    @input="handlePayload($event)"
                    :value="payload.end"
                />
            </div>
            <div class="divider"></div>
            <div class="manifest-action-container">
                <button
                    class="button yellow-outline"
                    type="button"
                    @click="handleClose"
                >
                    Cancel
                </button>
                <button class="button yellow" :disabled="isSubmitting">
                    Download
                </button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue"
const isSubmitting = ref(false)
const defaultPayload = { start: "", end: new Date().toJSON().slice(0, 10) }
const payload = ref(defaultPayload)

const handlePayload = (e) => {
    payload.value[e.target.name] = e.target.value
}

const props = defineProps({
    type: String,
})

const emit = defineEmits(["onSubmit", "onClose"])

const handleConfirm = (e) => {
    e.preventDefault()
    emit("onSubmit", { payload: payload.value, cb: handleClose })
}

const handleClose = () => {
    emit("onClose")
    isSubmitting.value = false
}
</script>

<style scoped>
.manifest-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.divider {
    margin: 32px 0;
}
input {
    width: 100%;
}
</style>
