<template>
    <div class="modal-container">
        <div class="scan-input">
            <div class="modal-confirmation-message">
                {{ props.message || "Are you sure?" }}
            </div>
            <div class="delete-action">
                <button class="button yellow" @click="handleClose">
                    Cancel
                </button>
                <button class="button red-outline" @click="handleConfirm">
                    {{ props.confirmMessage || "Confirm" }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
const props = defineProps({
    message: String,
    confirmMessage: String,
})

const emit = defineEmits(["onSubmit", "onClose"])

const handleConfirm = () => {
    emit("onSubmit", handleClose)
}

const handleClose = () => {
    emit("onClose")
}
</script>

<style scoped>
.delete-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0 0;
    gap: 16px;
}
</style>
