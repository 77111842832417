import { ref } from "vue"

const MAX_XHR_LOG_COUNT = 20
export const xhrLogs = []

const generateLog = (startTime, req, endTime, res) => {
    appendXhrLog({ startTime, endTime, req, res })
}
const appendXhrLog = (log) => {
    xhrLogs.push(log)
    if (xhrLogs.length > MAX_XHR_LOG_COUNT) {
        xhrLogs.shift()
    }
}

export const showLoader = ref(false)
export const setLoader = (e) => {
    showLoader.value = !!e
    if (e) {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflow = "unset"
    }
}

export const randomId = (size) =>
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join("")

export const DRAFT_KEY_LOCALSTORAGE = "dodoo_invoice_app:draft"

export const getLocalStorageDataByKey = (key, skipJsonParse) => {
    try {
        if (skipJsonParse) {
            return localStorage.getItem(key)
        }
        return JSON.parse(localStorage.getItem(key))
    } catch (error) {
        localStorage.removeItem(key)
        console.log(`Error parsing JSON, removing JSON with key: ${key}`)
        console.warn(error)
        return null
    }
}

export const setLocalStorageData = (key, modifier) => {
    try {
        const newData = modifier(getLocalStorageDataByKey(key))
        localStorage.setItem(key, JSON.stringify(newData))
        return [newData, null]
    } catch (error) {
        return [null, error]
    }
}

export const toastList = ref([])

export const toast = (msg, type = "success") => {
    const id = Math.floor(Math.random() * 1000)
    const timer = setTimeout(onRemoveToast, 3000, id)

    toastList.value.push({ id, msg, type, timer })
    if (toastList.value.length > 5) {
        toastList.value.pop()
    }
}

export const onRemoveToast = (id) => {
    toastList.value = toastList.value.filter((t) => t.id !== id)
}

const isJSONStringifyAble = (body) => {
    try {
        JSON.stringify(body)
        return true
    } catch (error) {
        return false
    }
}

export const downloadCSV = (urlData, fileName = "default.csv") => {
    const aLink = document.createElement("a")
    const evt = document.createEvent("HTMLEvents")
    evt.initEvent("click")
    aLink.download = fileName
    aLink.href = urlData
    aLink.dispatchEvent(evt)
}

export const formatAMPM = (d) => {
    const date = new Date(d)

    const da = date.toLocaleString().slice(0, 10).replaceAll("/", "-")

    let hours = date.getHours()
    let minutes = date.getMinutes()
    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes
    const strTime = hours + ":" + minutes + " " + ampm
    return da + " " + strTime
}

export const postApi = (url, body, headers = {}) => {
    if (body instanceof FormData) {
        headers = { ...headers }
    } else {
        if (isJSONStringifyAble(body)) {
            body = JSON.stringify(body)
            headers["Content-Type"] = "Application/JSON"
        }
    }
    return f("POST", url, body, { ...headers, Accept: "Application/JSON" })
}

export const getApi = (url) => {
    return f("GET", url, null, {
        Accept: "Application/JSON",
    })
}

export const deleteApi = (url) => {
    return f("DELETE", url, null, {
        Accept: "Application/JSON",
    })
}

const f = async (method, url, body, headers = {}) => {
    if (!["GET", "POST", "PUT", "DELETE"].includes(method.toUpperCase())) {
        throw new Error(`Invalid fetch method: ${method}`)
    }
    const start = Date.now()
    const response = await fetch(url, {
        method,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            ...headers,
        },
        body,
    })
    generateLog(
        start,
        {
            url,
            method,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                ...headers,
            },
            body,
        },
        Date.now(),
        {
            status: response.status,
            statusText: response.statusText,
            ok: response.ok,
            redirected: response.redirected,
            url: response.url,
            type: response.type,
        }
    )

    if (["POST", "DELETE"].includes(method.toUpperCase())) {
        setLoader(true)
    }

    try {
        if (response.status >= 400 && response.status < 500) {
            console.log(response.status, response.statusText)
            return { unauth: true }
        }
        if (response.status >= 500) {
            toast("Something went wrong", "error")
            setLoader(false)
            return
        }
        if (
            response.headers
                .get("Content-Type")
                .toLowerCase()
                .indexOf("application/csv") > -1
        ) {
            // console.log("CSV FOUND");
            setLoader(false)
            return response.text()
        }

        if (
            response.headers
                .get("Content-Type")
                .toLowerCase()
                .indexOf("text/html") > -1
        ) {
            toast("Something went wrong", "error")
            setLoader(false)
            return
            // return {error: "Something went wrong", msg: await response.text()};
        }
        const result = await response.json()
        setLoader(false)
        return result
    } catch (error) {
        // alert("Something went wrong");
        toast("Something went wrong", "error")
        console.warn(error)
        return error({ error })
    }
}

export const openNewTab = (url) => {
    window.open(url, "_blank")
}

export const printInvoiceByID = (id) => {
    const base = `${
        location.hostname === "localhost" ? "http://localhost:8000" : ""
    }`
    const url = `/invoices/print/${id}?token=${localStorage.getItem(
        "access_token"
    )}`
    const url_ = base + url
    openNewTab(url_)
}

export const STATES_MY = [
    "Johor",
    "Kedah",
    "Kelantan",
    "Kuala Lumpur",
    "Labuan",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Penang",
    "Perak",
    "Perlis",
    "Putrajaya",
    "Sabah",
    "Sarawak",
    "Selangor",
    "Terengganu",
]
