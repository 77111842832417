<template>
    <form @submit="handleSubmit" ref="mainFormRef" id="invoiceForm">
        <div class="duo-form">
            <div class="isolate-form" ref="primaryFormRef">
                <div class="form-title">Invoice to</div>
                <div>
                    <label>Company Name</label>
                    <input
                        @input="handlePrimaryForm_($event)"
                        placeholder="Company Name"
                        type="text"
                        name="company_name"
                        :value="data.company_name"
                    />
                </div>
                <div class="contact-detail-container">
                    <div>
                        <label required>Name</label>
                        <input
                            @input="handlePrimaryForm_($event)"
                            placeholder="Name"
                            required
                            minlength="5"
                            type="text"
                            name="name"
                            :value="data.name"
                        />
                    </div>
                    <div>
                        <label required>Contact No</label>
                        <input
                            @input="handlePrimaryForm_($event)"
                            placeholder="0123456789"
                            type="tel"
                            required
                            minlength="10"
                            name="contact"
                            :value="data.contact"
                        />
                    </div>
                </div>
                <div>
                    <label required>Unit No/Building</label>
                    <input
                        @input="handlePrimaryForm_($event)"
                        type="text"
                        required
                        placeholder="Unit No, Building"
                        name="unit_no"
                        :value="data.unit_no"
                    />
                </div>
                <div>
                    <label required>Address</label>
                    <textarea
                        rows="4"
                        @input="handlePrimaryForm_($event)"
                        placeholder="Unit No, Building, Street, Town"
                        required
                        minlength="6"
                        name="address"
                        :value="data.address"
                    ></textarea>
                </div>
                <div class="address-detail-container">
                    <div>
                        <label required>Postcode</label>
                        <input
                            @input="handlePrimaryForm_($event)"
                            required
                            minlength="5"
                            placeholder="00000"
                            maxlength="5"
                            type="number"
                            inputmode="numeric"
                            pattern="\d*"
                            name="postcode"
                            :value="data.postcode"
                        />
                    </div>
                    <div>
                        <label required>State</label>
                        <select
                            :class="{ placeholder: !data.state }"
                            name="state"
                            required
                            @change="handlePrimaryForm_($event)"
                            :value="data.state"
                        >
                            <option hidden value="">State</option>
                            <option
                                v-for="(state, i) in STATES_MY"
                                :key="i"
                                :value="state"
                            >
                                {{ state }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label required>City</label>
                        <input
                            list="cityList"
                            @input="handlePrimaryForm_($event)"
                            required
                            type="text"
                            placeholder="City"
                            name="city"
                            :value="data.city"
                        />
                        <datalist
                            id="cityList"
                            v-if="citySuggestions.get('city')"
                        >
                            <option
                                v-for="(city, i) in citySuggestions.get('city')"
                                :key="i"
                                :value="city"
                            ></option>
                        </datalist>
                    </div>
                </div>
            </div>
            <template v-for="(d, i) in data.recipients" :key="i">
                <div v-if="i > 0"></div>
                <div class="isolate-form">
                    <div class="form-title">
                        Deliver to
                        {{ data.recipients.length > 1 ? `(#${i + 1})` : "" }}
                    </div>
                    <div
                        class="toggle-primary-container"
                        :id="`toggle-primary-container-${i}`"
                    >
                        <label class="checkbox-container">
                            <input
                                type="radio"
                                :name="`is_using_invoice_address[${i}]`"
                                @change="toggleUsePrimary($event, i)"
                                value="on"
                                :checked="d.is_using_invoice_address"
                            />
                            <span>Same as invoice address</span>
                        </label>
                        <label class="checkbox-container">
                            <input
                                type="radio"
                                :name="`is_using_invoice_address:[${i}]`"
                                @change="toggleUsePrimary($event, i)"
                                value="off"
                                :checked="!d.is_using_invoice_address"
                            />
                            <span>Use a different address</span>
                        </label>
                    </div>
                    <div
                        v-if="d"
                        :class="`isolate-form ${
                            d.is_using_invoice_address ? 'hide' : ''
                        }`"
                    >
                        <div>
                            <label>Company Name</label>
                            <input
                                :disabled="d.is_using_invoice_address"
                                @input="handleRecipientForm_($event)"
                                type="text"
                                placeholder="Company Name"
                                :name="`recipients[${i}][company_name]`"
                                :value="d['company_name']"
                            />
                        </div>
                        <div class="contact-detail-container">
                            <div>
                                <label required>Name</label>
                                <input
                                    :disabled="d.is_using_invoice_address"
                                    @input="handleRecipientForm_($event)"
                                    required
                                    minlength="5"
                                    type="text"
                                    placeholder="Name"
                                    :name="`recipients[${i}][name]`"
                                    :value="d['name']"
                                />
                            </div>
                            <div>
                                <label required>Contact No</label>
                                <input
                                    :disabled="d.is_using_invoice_address"
                                    @input="handleRecipientForm_($event)"
                                    type="tel"
                                    required
                                    minlength="10"
                                    placeholder="0123456789"
                                    :name="`recipients[${i}][contact]`"
                                    :value="d['contact']"
                                />
                            </div>
                        </div>
                        <div>
                            <label required>Unit No/Building</label>
                            <input
                                :disabled="d.is_using_invoice_address"
                                @input="handleRecipientForm_($event)"
                                type="text"
                                required
                                placeholder="Unit No, Building"
                                :name="`recipients[${i}][unit_no]`"
                                :value="d['unit_no']"
                            />
                        </div>
                        <div>
                            <label required>Address</label>
                            <textarea
                                :disabled="d.is_using_invoice_address"
                                @input="handleRecipientForm_($event)"
                                rows="4"
                                required
                                minlength="6"
                                placeholder="Unit No, Building, Street, Town"
                                :name="`recipients[${i}][address]`"
                                :value="d['address']"
                            ></textarea>
                        </div>
                        <div class="address-detail-container">
                            <div>
                                <label required>Postcode</label>
                                <input
                                    :disabled="d.is_using_invoice_address"
                                    @input="handleRecipientForm_($event)"
                                    required
                                    minlength="5"
                                    maxlength="5"
                                    type="number"
                                    placeholder="00000"
                                    inputmode="numeric"
                                    pattern="\d*"
                                    :name="`recipients[${i}][postcode]`"
                                    :value="d['postcode']"
                                />
                            </div>
                            <div>
                                <label required>State</label>
                                <select
                                    :disabled="d.is_using_invoice_address"
                                    :class="{ placeholder: !d.state }"
                                    required
                                    @change="handleRecipientForm_($event)"
                                    :name="`recipients[${i}][state]`"
                                    :value="d['state']"
                                >
                                    <option hidden value="">State</option>
                                    <option
                                        v-for="(state, i) in STATES_MY"
                                        :key="i"
                                        :value="state"
                                    >
                                        {{ state }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label required>City</label>
                                <input
                                    :disabled="d.is_using_invoice_address"
                                    @input="handleRecipientForm_($event)"
                                    :list="`cityList[${i}]`"
                                    required
                                    type="text"
                                    :name="`recipients[${i}][city]`"
                                    :value="d['city']"
                                    placeholder="City"
                                />
                                <datalist
                                    :id="`cityList[${i}]`"
                                    v-if="
                                        citySuggestions.get(
                                            `recipients[${i}][city]`
                                        )
                                    "
                                >
                                    <option
                                        v-for="(city, i) in citySuggestions.get(
                                            `recipients[${i}][city]`
                                        )"
                                        :key="i"
                                        :value="city"
                                    ></option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="isolate-form">
                    <div class="form-title">
                        Parcel Detail
                        {{ data.recipients.length > 1 ? `(#${i + 1})` : "" }}
                    </div>
                    <div class="parcel-meta-container">
                        <div>
                            <label required>Item Description</label>
                            <input
                                @input="handleRecipientForm_($event)"
                                :value="d['description']"
                                required
                                type="text"
                                :name="`recipients[${i}][description]`"
                                placeholder="Clothes"
                            />
                        </div>
                        <div>
                            <label required>Item Value</label>
                            <div class="decoration">
                                <span class="prefix">RM</span>
                                <input
                                    @input="handleRecipientForm_($event)"
                                    :value="d['value']"
                                    required
                                    type="number"
                                    min="0.01"
                                    step="0.01"
                                    :name="`recipients[${i}][value]`"
                                    placeholder="0.00"
                                />
                            </div>
                        </div>
                        <div>
                            <label required>Parcel Weight</label>
                            <div class="decoration">
                                <span class="suffix">KG</span>
                                <input
                                    @input="handleRecipientForm_($event)"
                                    :value="d['weight']"
                                    required
                                    type="number"
                                    min="0.01"
                                    step="0.01"
                                    :name="`recipients[${i}][weight]`"
                                    placeholder="0.00"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="parcel-dimension-container">
                        <div>
                            <label>Width</label>
                            <div class="decoration">
                                <span class="suffix">CM</span>
                                <input
                                    @input="handleRecipientForm_($event)"
                                    :value="d['width']"
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :name="`recipients[${i}][width]`"
                                    placeholder="0.0"
                                />
                            </div>
                        </div>
                        <div>
                            <label>Height</label>
                            <div class="decoration">
                                <span class="suffix">CM</span>
                                <input
                                    @input="handleRecipientForm_($event)"
                                    :value="d['height']"
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :name="`recipients[${i}][height]`"
                                    placeholder="0.0"
                                />
                            </div>
                        </div>
                        <div>
                            <label>Length</label>
                            <div class="decoration">
                                <span class="suffix">CM</span>
                                <input
                                    @input="handleRecipientForm_($event)"
                                    :value="d['length']"
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    :name="`recipients[${i}][length]`"
                                    placeholder="0.0"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>Tracking No</label>
                        <input
                            @input="handleRecipientForm_($event)"
                            :value="d['tracking_no']"
                            type="text"
                            :name="`recipients[${i}][tracking_no]`"
                            placeholder="Tracking No"
                        />
                    </div>
                </div>
            </template>
        </div>
        <!-- <button @click="logger">Logger</button> -->
        <button hidden></button>
    </form>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    defineEmits,
    defineProps,
    computed,
} from "vue"
import { toast, getApi, STATES_MY } from "../../util/dodoo"

const props = defineProps({
    data: Object,
    onSubmit: Function,
})

const emit = defineEmits(["onSubmit"])

const defaultBilling = {
    company_name: "",
    name: "",
    contact: "",
    unit_no: "",
    address: "",
    postcode: "",
    state: "",
    city: "",
}

const citySuggestions = ref(new Map())

const mainFormRef = ref()
const primaryFormRef = ref()
const data = computed(() => props.data)

const handleSubmit = (e) => {
    e.preventDefault()
    emit("onSubmit", data.value)
}

const debounce = (cb, delay = 800) => {
    let timeout
    return (...args) => {
        const later = () => {
            clearTimeout(timeout)
            cb(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, delay)
    }
}

const fetchCitiesWithDelay = debounce(async (input, name) => {
    const r = await getApi(`/invoices/get_cities/${input}`)
    if (r.error) {
        toast(r.error, "error")
    }
    if (r.cities && r.cities.length) {
        citySuggestions.value.set(
            name,
            r.cities.map((c) => c.city)
        )
    }
})

const toggleUsePrimary = (e, i) => {
    if (e.target.value == "on") {
        // primaryFormRef.value.querySelectorAll("input").forEach((el)=>{
        //     document.querySelector(`[name='recipients[0][${el.name}]']`).value = el.value;
        // })
        // primaryFormRef.value.querySelectorAll("textarea").forEach((el)=>{
        //     document.querySelector(`[name='recipients[0][${el.name}]']`).value = el.value;
        // })
        // primaryFormRef.value.querySelectorAll("select").forEach((el)=>{
        //     document.querySelector(`[name='recipients[0][${el.name}]']`).value = el.value;
        //     document.querySelector(`[name='recipients[0][${el.name}]']`).classList.remove("placeholder");
        // })
    } else {
        // data.value.recipients = [{...data.value.recipients[i], ...defaultBilling}];
        data.value.recipients = data.value.recipients.map((r, ind) =>
            ind == i ? { ...r, ...defaultBilling } : r
        )
    }
    data.value.recipients[i].is_using_invoice_address =
        e.target.value == "on" ? 1 : 0
    // isUsePrimary.value = e.target.value == 'on' ? 1 : 0;
}

const fetchAndPopulateStateAndCity = async (postcode, name) => {
    const _stateName = name.replace("postcode", "state")
    const _cityName = name.replace("postcode", "city")
    const r = await getApi(`/invoices/get_states_cities/${postcode}`)
    if (r.error) {
        toast(r.error, "error")
    }
    if (r.cities && r.cities.length) {
        const _state = r.cities[0].state
        const _cities = r.cities.map((c) => c.city)
        citySuggestions.value.set(_cityName, _cities)

        // Side effects
        if (!name.includes("recipients")) {
            data.value[_stateName] = _state
            data.value[_cityName] = _cities[0]
        } else {
            const [index, key] = sliceRecipientByName(_stateName)
            data.value.recipients[index][key] = _state
            const [index_, key_] = sliceRecipientByName(_cityName)
            data.value.recipients[index_][key_] = _cities[0]
        }
    } else {
        if (data.value.reuse) {
            citySuggestions.value.delete(`recipients[0][${_stateName}]`)
        }
        citySuggestions.value.delete(_cityName)
    }
}

const sliceRecipientByName = (name) => {
    const regExp = /\[.*?\]/g
    const matches = name.match(regExp)
    if (matches) {
        return matches.map((m) => m.replace("[", "").replace("]", ""))
    }
    return null
}

const handlePrimaryForm_ = (e) => {
    data.value[e.target.name] = e.target.value

    if (e.target.tagName === "SELECT") {
        e.target.classList.remove("placeholder")
    }
    if (e.target.name.includes("postcode")) {
        if (e.target.value.length === 5) {
            fetchAndPopulateStateAndCity(e.target.value, e.target.name)
        }
    }
    if (e.target.name.includes("city")) {
        if (e.target.value.length > 1) {
            fetchCitiesWithDelay(e.target.value, e.target.name)
        }
    }
}

const handleRecipientForm_ = (e) => {
    const [index, key] = sliceRecipientByName(e.target.name)

    if (data.value.recipients && Array.isArray(data.value.recipients)) {
        data.value.recipients[index][key] = e.target.value
    } else {
        data.value.recipients = [{ [key]: e.target.value }]
    }

    if (e.target.tagName === "SELECT") {
        e.target.classList.remove("placeholder")
    }
    if (e.target.name.includes("postcode")) {
        if (e.target.value.length === 5) {
            fetchAndPopulateStateAndCity(e.target.value, e.target.name)
        }
    }
    if (e.target.name.includes("city")) {
        if (e.target.value.length > 1) {
            fetchCitiesWithDelay(e.target.value, e.target.name)
        }
    }
}

const handleSelectInitialAction = (e) => {
    if (e.target.tagName === "SELECT") {
        e.target.classList.remove("placeholder")
    }
}
onMounted(() => {
    document.addEventListener("change", handleSelectInitialAction)
})

onBeforeUnmount(() => {
    document.removeEventListener("change", handleSelectInitialAction)
})
</script>
